/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { AtSign, BarChart2, BookOpen, Droplet, GitHub, LogOut, Monitor, Users } from 'react-feather'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import logoWhite from '../assets/img/logo/logo-white.png'
import { logout } from '../helpers/common'

function Sidebar() {
  const { pathname } = useLocation()
  const redux = useSelector(state => state.redux)

  return (
    <nav id='sidebar' className={`sidebar ${!redux.openSidebar ? 'collapsed' : ''}`}>
      <div className='sidebar-content position-sticky top-0'>
        <Link className='sidebar-brand' to='/'>
          <span className='align-middle'>
            <span><img src={logoWhite} alt='logo-scoola' width={100}/></span> <sub className='text-warning'>By Kerjoo</sub>
          </span>
        </Link>
        <ul className='sidebar-nav'>
          <li className='sidebar-header'>
            Analisa
          </li>
          <li className={`sidebar-item ${pathname === '/dashboard' ? 'active' : ''}`}>
            <Link className='sidebar-link' to='/dashboard'>
              <BarChart2 className='align-middle' size={18} />
              <span className='align-middle'>Dasbor</span>
            </Link>
          </li>
          <li className={`sidebar-item ${pathname === '/attendance-report' ? 'active' : ''}`}>
            <Link className='sidebar-link' to='/attendance-report'>
              <BookOpen className='align-middle' size={18} />
              <span className='align-middle'>Laporan Presensi</span>
            </Link>
          </li>
          <li className='sidebar-header'>
            Pengaturan
          </li>
          <li className={`sidebar-item ${pathname === '/attendance-page' ? 'active' : ''}`}>
            <Link className='sidebar-link' to='/attendance-page'>
              <Monitor className='align-middle' size={18} />
              <span className='align-middle'>Halaman Presensi</span>
            </Link>
          </li>
          <li className={`sidebar-item ${pathname.split('/').includes('participant') ? 'active' : ''}`}>
            <Link className='sidebar-link' to='/participant'>
              <Users className='align-middle' size={18} />
              <span className='align-middle'>Peserta Presensi</span>
            </Link>
          </li>
          <li className={`sidebar-item ${pathname === '/company-profile' ? 'active' : ''}`}>
            <Link className='sidebar-link' to='/company-profile'>
              <GitHub className='align-middle' size={18} />
              <span className='align-middle'>Profil Sekolah</span>
            </Link>
          </li>
          <li className='sidebar-header'>
            Akun
          </li>
          <li className={`sidebar-item ${pathname === '/subscription' ? 'active' : ''}`}>
            <Link className='sidebar-link' to='/subscription'>
              <Droplet className='align-middle' size={18} />
              <span className='align-middle'>Langganan</span>
            </Link>
          </li>
          <li className={`sidebar-item ${pathname === '/admin' ? 'active' : ''}`}>
            <Link className='sidebar-link' to='/admin'>
              <AtSign className='align-middle' size={18} />
              <span className='align-middle'>Admin</span>
            </Link>
          </li>
          <li className={`sidebar-item`}>
            <a className='sidebar-link' onClick={logout}>
              <LogOut className='align-middle' size={18} />
              <span className='align-middle'>Keluar</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Sidebar
